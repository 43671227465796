import { useState } from 'react';
import useSWR from 'swr';
import { useInfiniteScroll } from './useInfiniteScroll';
import { paginate } from './paginationHelper';
import { Patient } from './usePatients';
import { Doctor } from 'types/doctor';
import { patch } from './http';
import { Practice } from '../types/practice';

type AppointmentsData = {
  results: Array<Appointment>;
  count: number;
  next: string;
  previous: string;
};

export type Appointment = {
  doctor: Doctor;
  format: string;
  id: number;
  patient: Patient;
  practice: Practice;
  state: string;
  therapy_state: string;
  created_at: string;
  video_consultation_tan: string;
  pharmacy: number | null;
  all_pzn_provided: boolean;
};

const pageSize = 25;

export function usePatientAppointments(patientId: number | string | undefined) {
  const { data, error, mutate } = useSWR<AppointmentsData>(
    `medical/patient-appointments/?patient__id=${patientId}`,
  );

  return { data, error, mutate };
}

export function useAppointments(patientId: number) {
  const [search, setSearch] = useState('');
  const paginationData = useInfiniteScroll<AppointmentsData>({
    url: `medical/appointments/?patient=${patientId}/`,
    pageSize: 1,
  });

  return { search, setSearch, ...paginationData };
}

export function updateAppointmentState({ id, state }: { id: number; state: string }) {
  return patch(`medical/patient-appointments/${id}/`, { state });
}

export function updateTherapyState({ id, state }: { id: number; state: string }) {
  return patch(`medical/patient-appointments/${id}/`, { therapy_state: state });
}

export function updateAppointment(id: number, patchObject: any) {
  return patch(`medical/patient-appointments/${id}/`, patchObject);
}

export function usePaginatedAppointments({
  search,
  state,
  page = 1,
  date,
}: {
  search?: string;
  state?: string;
  page?: number;
  date?: string | null;
}) {
  const { data, mutate, error } = useSWR<AppointmentsData>(
    `medical/patient-appointments/?limit=${pageSize}&offset=${pageSize * (page - 1)}${
      date ? `&date=${date}` : ''
    }${search ? '&search=' + search : ''}${state ? '&state=' + state : ''}`,
  );

  const paginationInfo = data ? paginate(data?.count ?? 0, page, pageSize) : null;

  const isLoading = !error && !data;

  return { appointments: data?.results ?? [], mutate, paginationInfo, isLoading };
}
