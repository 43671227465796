import { Anamnesis, useAnamneses } from 'data/useAnamnesis';
import { Examination, useExaminations } from 'data/useExaminations';
import { Note, useNotes } from 'data/useNotes';
import { Patient } from 'data/usePatients';
import { Prescription, usePrescriptions } from 'data/usePrescriptions';
import AnamnesisSection from './AnamnesisSection';
import DiagnosisSection, { PatientDiagnosisItem } from './DiagnosisSection';
import ExaminationSection from './ExaminationSection';
import NoteSection from './NoteSection';
import PrescriptionSection from './PrescriptionSection';
import { groupBy, findIndex } from 'lodash';
import { format, isAfter, startOfDay } from 'date-fns';
import IndicationSection from './IndicationSection';
import { Indication, useIndications } from 'data/useIndication';
import { useAppointmentContext } from 'contexts/Appointment';
import { useEffect } from 'react';

const sectionTypes = {
  anamnesis: AnamnesisSection,
  examination: ExaminationSection,
  note: NoteSection,
  prescription: PrescriptionSection,
  diagnosis: DiagnosisSection,
  indication: IndicationSection,
};

type Props = {
  patient: Patient;
  appointmentId: number;
  pharmacyId: number | null;
};

export default function SectionFeed({ patient, appointmentId, pharmacyId }: Props) {
  const { state, dispatch } = useAppointmentContext();
  const { results: anamneses } = useAnamneses(patient.id, appointmentId);
  const { results: examinations } = useExaminations(patient.id, appointmentId);
  const { results: notes } = useNotes(patient.id, appointmentId);
  const { results: prescription } = usePrescriptions(patient.id, appointmentId);
  const { results: indications } = useIndications(patient.id, appointmentId);
  const diagnosis = getPatientDiagnosis(patient, appointmentId);
  const emptyDiagnosis = getEmptyPatientDiagnosis(patient, appointmentId, state.showEmptyDiagnosis);

  const feed: Array<Anamnesis | Examination | Note | Prescription | Indication> = anamneses
    .map(setType('anamnesis'))
    .concat(examinations.map(setType('examination')))
    .concat(emptyDiagnosis)
    .concat(diagnosis)
    .concat(indications.map(setType('indication')))
    .concat(prescription.map((p) => ({ ...p, created_at: p.date })).map(setType('prescription')))
    .concat(notes.map(setType('note')))
    .map(setTimestamp)
    .sort(orderByDate);

  const orderedFeed = groupBy(feed, (i: any) => startOfDay(i.created_at).toISOString());
  const sortedFeed = Object.entries(orderedFeed).sort((a: any, b: any) =>
    isAfter(new Date(a[0]), new Date(b[0])) ? -1 : 1,
  );

  const addSortOrderProp = (type: string) => {
    switch (type) {
      case 'anamnesis':
        return 1;
      case 'examination':
        return 2;
      case 'diagnosis':
        return 3;
      case 'indication':
        return 4;
      case 'prescription':
        return 5;
      case 'note':
        return 6;
    }
  };

  useEffect(() => {
    const sections = [
      'anamnesis',
      'examination',
      'diagnosis',
      'indication',
      'prescription',
      'note',
    ];

    const feedSet = new Set();
    feed.forEach((item: any) => {
      if (
        item.type === 'note' &&
        startOfDay(item.created_at).toISOString() !== startOfDay(new Date()).toISOString()
      ) {
        return;
      }
      feedSet.add(item.type);
    });

    // set initial state
    sections.forEach((section) => {
      if (Array.from(feedSet).includes(section)) {
        dispatch({ type: section, payload: true });
      } else {
        dispatch({ type: section, payload: false });
      }
    });
  }, [state.refresh]);

  if (!sortedFeed.length) {
    return (
      <div className="border border-dashed border-[#c2dcfc] rounded h-44 flex items-center justify-center">
        <span className="text-[#85b9fa]">Karten hinzufügen</span>
      </div>
    );
  }

  const sectionsMap = groupBy(feed, (item: any) => {
    return item.type;
  });
  return (
    <ul className="mb-16" data-appointment={appointmentId}>
      {sortedFeed.map(([date, items]: any, i: any) => {
        const feedDate = format(new Date(date), 'dd.MM.yyyy');
        const sortedByPatientActions = items
          .map((item: any, i: any) => ({
            ...item,
            sortOrder: addSortOrderProp(item.type),
          }))
          .sort((a: any, b: any) => a.sortOrder - b.sortOrder);

        return (
          <li key={i} className="mb-6 last:mb-0" data-date={feedDate}>
            <div className="w-full relative border-b my-12">
              <span className="absolute left-2/4 -translate-x-1/2 -translate-y-1/2 bg-white px-2 py-1 border rounded font-semibold text-swopa-secondary-light-blue">
                {feedDate}
              </span>
            </div>
            <ul>
              {sortedByPatientActions.map((item: any, j: any) => {
                const count = findIndex(
                  sectionsMap[item.type],
                  (i) => {
                    return i.id === item.id;
                  },
                  0,
                );

                const total = sectionsMap[item.type].length;

                // @ts-ignore
                const Component = sectionTypes[item.type] ?? MissingItem;
                const isCreatedToday =
                  startOfDay(item.created_at).toISOString() ===
                  startOfDay(new Date()).toISOString();
                return (
                  <li key={`${j}-${item.id}`} className="mb-4 last:mb-0" data-type={item.type}>
                    <Component
                      key={item.id}
                      item={item}
                      count={count}
                      total={total}
                      expanded={
                        (item.type === state.selectedSection && isCreatedToday) ||
                        (!state.selectedSection && i === 0 && j === 0)
                      }
                      appointmentId={appointmentId}
                      pharmacyId={pharmacyId}
                    />
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
}

function MissingItem() {
  return null;
}

function getEmptyPatientDiagnosis(
  patient: Patient,
  appointmentId: number,
  showEmptyDiagnosis: boolean,
): PatientDiagnosisItem[] {
  if (showEmptyDiagnosis) {
    return [
      {
        patient: patient.id,
        appointmentId,
        diagnosis: [],
        created_at: new Date().toISOString(),
        type: 'diagnosis',
      },
    ];
  }
  return [];
}

function getPatientDiagnosis(patient: Patient, appointmentId: number): PatientDiagnosisItem[] {
  const filteredDiagnosis = patient?.diagnosis?.filter(
    (diagnose) => diagnose.appointment?.id === appointmentId,
  );
  const orderedFeed = groupBy(filteredDiagnosis, (i: any) =>
    startOfDay(new Date(i.created_at)),
  ) as any;
  const sortedFeed = Object.entries(orderedFeed).map(([date, diagnosis]) => ({
    patient: patient.id,
    appointmentId,
    diagnosis: diagnosis,
    created_at: new Date(date).toISOString(),
    type: 'diagnosis',
  }));
  if (sortedFeed.length) {
    return sortedFeed as any;
  }
  return [];
}

const setType: <T>(type: string) => (obj: T) => T = (type) => (obj) => ({
  ...obj,
  type,
});

const setTimestamp: <T extends { created_at: string }>(obj: T) => T = (obj) => ({
  ...obj,
  created_at: new Date(obj.created_at),
});

const orderByDate = (a: any, b: any) =>
  isAfter(new Date(a.created_at), new Date(b.created_at)) ? -1 : 1;
