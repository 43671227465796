import { AppointmentContextState, Action } from '../contexts/Appointment';

export default function useAppointment({
  dispatch,
  state,
}: {
  dispatch: (obj: Action) => void;
  state: AppointmentContextState;
}) {
  const sectionExistsInDOM = (type: string) =>
    state[`${type}Active` as keyof AppointmentContextState];

  const setActive = (type: string) => dispatch({ type, payload: true });

  const setSelectedSection = (section: string) =>
    dispatch({ type: 'selectedSection', payload: section });

  const setShowEmptyDiagnosis = (showEmptyDiagnosis: boolean) =>
    dispatch({ type: 'setShowEmptyDiagnosis', payload: showEmptyDiagnosis });

  const refreshState = () => dispatch({ type: 'refresh', payload: Math.random() });

  const setSelectedPharmacyId = (pharmacyId: number) =>
    dispatch({ type: 'selectedPharmacyId', payload: pharmacyId });

  return {
    sectionExistsInDOM,
    setActive,
    setSelectedSection,
    setSelectedPharmacyId,
    setShowEmptyDiagnosis,
    refreshState,
  };
}
