import { useMemo } from 'react';
import type { Localization } from '../localizations/types';

export default function useLocalization(): Localization {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as Localization;

  return useMemo((): Localization => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return LOCALIZATION;
  }, [LOCALIZATION]);
}
