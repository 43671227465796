import { useState, useRef } from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowRight } from 'icons/arrow_right.svg';
import { diagnosisCertaintyLevels } from 'utils/diagnosisCertaintyLevels';
import CertaintySymbol from './CertaintySymbol';

type Props = {
  index: number;
  result: any;
  activeSuggestion: any;
  handleSelect: (result: string, certainty: any) => any;
};

export default function DiagnosisAutocompleteSuggestion(props: Props) {
  const { index, result, activeSuggestion, handleSelect } = props;
  const [showCategories, setShowCategories] = useState(false);
  const [listElemPositionValues, setListElemPositionValues] = useState({ top: 0, left: 0 });
  const suggestionRef = useRef() as any;

  const handleMouseEnter = () => {
    const li = suggestionRef.current;
    const offsetTop = Math.round(li.getBoundingClientRect().top);
    const offsetLeft = Math.round(li.getBoundingClientRect().right);
    setListElemPositionValues({ top: offsetTop, left: offsetLeft });
    setShowCategories(true);
  };

  const handleMouseLeave = () => {
    setShowCategories(false);
  };

  const handleAddDiagnosis = async (e: any, result: any, category: any) => {
    e.stopPropagation();
    handleSelect(result, category);
  };

  return (
    <div
      className={classNames(
        'text-swopa-primary-dark-blue group hover:bg-swopa-grey-1 px-3 pr-7 py-2 cursor-pointer relative max-w-[320px]',
        { 'pointer-events-none hover:bg-none': typeof result === 'string' },
        // { 'bg-swopa-grey-1': activeSuggestion === index }, TODO: add keyboard support
      )}
      onClick={() => handleSelect(result, 'secured')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={suggestionRef}
    >
      <>
        {typeof result === 'string' ? (
          <span className="text-sm">{result}</span>
        ) : (
          <div className="flex flex-col">
            <span className="text-sm block mb-1">{result.name}</span>
            <span className="font-light text-sm">{result.icd_10_gm}</span>
          </div>
        )}
      </>
      <ArrowRight className="absolute right-3 top-[50%] translate-y-[-50%] stroke-swopa-grey-3 w-1.5 group-hover:stroke-swopa-secondary-light-blue-hover" />
      <ul
        style={{
          top: `${listElemPositionValues.top}px`,
          left: `${listElemPositionValues.left}px`,
          display: `${showCategories ? 'block' : 'none'}`,
        }}
        className={classNames('bg-white rounded shadow-md fixed z-10')}
      >
        {diagnosisCertaintyLevels.map((level, i) => (
          <li
            className="py-2 px-3 flex items-center hover:bg-swopa-grey-1"
            onClick={(e) => handleAddDiagnosis(e, result, level.value)}
            key={i}
          >
            <CertaintySymbol certainty={level.shortname} className="mr-1" />
            <span className="text-swopa-primary-dark">{level.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
