import { useEffect, useState, useRef } from 'react';

import { ReactComponent as ArrowDown } from 'icons/arrow_down.svg';

type Props = {
  options: Array<any>;
  handleChange: (e: any) => any;
  selected: string;
  name: string;
  external_id: string;
};

export default function DocumentCategorySelect(props: Props) {
  const { options, handleChange, selected, name, external_id } = props;
  const selectElementRef = useRef() as any;
  const [selectElementWidth, setSelectElementWidth] = useState('auto');

  useEffect(() => {
    const select = selectElementRef.current;
    const selectedText = select.options[select.selectedIndex].text;
    const formula = selectedText.length > 36 ? selectedText.length * 7.5 : selectedText.length * 8;
    const selectedWidth = `${formula + 32}px`;
    setSelectElementWidth(selectedWidth);
  }, [selected]);

  return (
    <div className="relative" style={{ width: selectElementWidth }}>
      <select
        id="documentCategorySelect"
        ref={selectElementRef}
        onChange={handleChange}
        name={name}
        value={selected}
        className="text-swopa-secondary-light-blue outline-none font-radikal text-base disabled:cursor-not-allowed"
        title={
          !!external_id ? 'Rezept gesendet. Dokumentenart kann nicht geändert werden.' : undefined
        }
        disabled={!!external_id}
        style={{ width: selectElementWidth }}
      >
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ArrowDown className="absolute top-[50%] translate-y-[-50%] right-0 stroke-swopa-secondary-grey pointer-events-none" />
    </div>
  );
}
