import { Prescription } from '../../../data/usePrescriptions';
import { Dialog } from '@headlessui/react';
import { Patient } from '../../../data/usePatients';
import SignatureCanvas from 'react-signature-canvas';
import { DocumentProps, usePDF } from '@react-pdf/renderer';
import ReceiptPdf from '../pdf/Receipt';
import React, { ChangeEventHandler, useEffect, useId } from 'react';
import { useReceipt } from '../../../hooks/useReceipt';
import { Doctor } from '../../../types/doctor';

interface ReceiptModalProps {
  open: boolean;
  onClose: () => void;
  prescriptions: Prescription[];
  patient: Patient;
  doctor: Doctor;
  pharmacyId: number | null;
}

interface DownloadButtonProps {
  isValid: boolean;
  fileName: string;
  document:
    | React.ReactElement<DocumentProps, string | React.JSXElementConstructor<any>>
    | undefined;
}

type InputProps = {
  className: string;
  label: string;
  value: any;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean;
};
const Input = (props: InputProps) => {
  const id = useId();

  return (
    <div className={props.className}>
      <label htmlFor={id} className="block mb-2 text-xs font-medium">
        {props.label}
      </label>
      <input
        type="text"
        id={id}
        className="text-sm p-2 bg-swopa-white focus:outline-none focus:border-swopa-secondary-light-blue enabled:hover:border-swopa-secondary-light-blue w-full border rounded-md border-swopa-grey-3 disabled:bg-gray-100"
        disabled={!props.onChange || props.disabled}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};

const DownloadButton = ({ isValid, fileName, document }: DownloadButtonProps) => {
  const classes =
    'col-start-11 col-span-2 p-2 rounded-[4px] bg-swopa-secondary-light-blue font-radikal text-white text-xs uppercase flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none hover:bg-[#2B81EC] hover:ease-in-out';
  const [pdf, updateDocument] = usePDF({ document });

  useEffect(() => {
    document && updateDocument(document);
  }, [document]);

  if (!isValid) {
    return (
      <button className={classes} type="button" disabled>
        Warten auf Daten...
      </button>
    );
  }

  if (pdf.loading) {
    return (
      <button className={classes} type="button" disabled>
        Laden...
      </button>
    );
  }

  return (
    <a href={pdf.url || ''} download={fileName} className={classes}>
      Download
    </a>
  );
};

export function ReceiptModal({
  open,
  onClose,
  prescriptions,
  patient,
  doctor,
  pharmacyId,
}: ReceiptModalProps) {
  let signatureCanvas = React.useRef<SignatureCanvas>(null);

  const receipt = useReceipt(prescriptions, patient, doctor, pharmacyId);

  const clearSignature = () => {
    signatureCanvas.current?.clear();
    receipt.onSignatureUpdate('');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="fixed top-0 left-0 w-full h-screen bg-swopa-secondary-light-blue/50 filter backdrop-blur-md z-50"
    >
      <div className="flex w-screen h-full items-center justify-center p-4">
        <Dialog.Panel className="p-4 h-full bg-white border rounded max-w-6xl overflow-auto">
          <Dialog.Title>Digitales Rezept</Dialog.Title>
          <h4 className="text-blue-500 mb-2 font-bold">Patientendaten</h4>
          <div className="grid grid-cols-12 gap-4 mb-2">
            <Input className="col-span-4" label="Nachname" value={receipt.patient.last_name} />
            <Input className="col-span-4" label="Vorname" value={receipt.patient.first_name} />
            <Input
              className="col-span-3"
              label="Geburstdatum"
              value={receipt.patient.date_of_birth}
            />
            <Input
              className="col-span-6"
              label="Adresse"
              value={`${receipt.patient.street}, ${receipt.patient.postal_code}, ${receipt.patient.city}`}
            />
            <Input
              className="col-span-2"
              label="Telefonnummer"
              value={receipt.patient.phone_number}
            />
            <Input className="col-span-4" label="Email" value={receipt.patient.email} />
          </div>
          <h4 className="text-blue-500 mb-2 font-bold">Verschreibungen</h4>
          {receipt.prescriptions.map((p, i) => {
            let n = i + 1;
            return (
              <div key={n} className="mb-5">
                <h5 className="text-blue-500">Verschreibung {n}</h5>
                <div className="grid grid-cols-12 gap-4">
                  <Input className="col-span-6" label="Präparat" value={p.title} />
                  <Input className="col-span-6" label="Hersteller" value={p.producer} />
                  <Input className="col-span-2" label="Abgabeform" value={p.form} />
                  <Input className="col-span-2" label="Menge" value={p.amount} />
                  <Input className="col-span-2" label="Einheit" value={p.unit} />
                  <Input className="col-span-2" label="Anteil THC" value={p.share_of_thc} />
                  <Input className="col-span-2" label="Anteil CBD" value={p.share_of_cbd} />
                  <Input className="col-span-2" label="Dauer" value={p.duration} />
                  <Input className="col-span-12" label="Rezept Text" value={p.prescription_text} />
                </div>
              </div>
            );
          })}
          <h4 className="text-blue-500 mb-2 font-bold">Arztdaten</h4>
          <div className="grid grid-cols-12 gap-4 mb-2">
            <Input className="col-span-4" label="Nachname" value={receipt.doctor.last_name} />
            <Input className="col-span-4" label="Vorname" value={receipt.doctor.first_name} />
            <Input
              className="col-span-6"
              label="Adresse"
              value={`${receipt.doctor.practice?.street}, ${receipt.doctor.practice?.postal_code}, ${receipt.doctor.practice?.city}`}
            />
            <Input
              className="col-span-2"
              label="Telefonnummer"
              value={receipt.doctor.practice?.phone_number}
            />
            <Input className="col-span-4" label="Email" value={receipt.doctor.practice?.email} />
            <Input
              className="col-span-6"
              label="Apotheke"
              disabled={receipt.pharmacySelected}
              value={receipt.pharmacy}
              onChange={receipt.onPharmacyUpdate}
            />
            <div className="col-span-6">
              <label className="block mb-2 text-xs font-medium">Unterschrift</label>
              <SignatureCanvas
                ref={signatureCanvas}
                onEnd={() => receipt.onSignatureUpdate(signatureCanvas.current!.toDataURL())}
                canvasProps={{ className: 'border rounded border-gray-300 w-full h-[190px]' }}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 mb-2">
            <button
              type="button"
              onClick={clearSignature}
              disabled={signatureCanvas.current?.isEmpty()}
              className="col-start-9 col-span-2 p-2 rounded-[4px] bg-swopa-warning-red font-radikal text-white text-xs uppercase text-center disabled:opacity-50"
            >
              Unterschrift entfernen
            </button>
            <DownloadButton
              isValid={receipt.isValid}
              fileName={receipt.fileName}
              document={<ReceiptPdf data={receipt} />}
            />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
