import useSWR from 'swr';

export type Pharmacy = {
  id: number;
  name: string;
  api_integration: boolean;
};
export function usePharmacies() {
  const { data, error, mutate } = useSWR<any>(`medical/pharmacies/`);
  return { data: data?.results as Array<Pharmacy>, error, mutate };
}
