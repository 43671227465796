import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { get } from '../../../data/http';
import Spinner from '../../Spinner';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import { ReactComponent as Warning } from 'icons/warning.svg';
import { ReactComponent as Error } from 'icons/error.svg';

export default function PharmaceuticsAvailabilityCheckButton(props: {
  pzn: string;
  pharmacyId: number | null;
}) {
  type State = {
    buttonContent: ReactNode;
    buttonClass: string;
    helpText: string;
    disabled: boolean;
  };

  const stateMap = {
    disabled: {
      buttonContent: 'Verfügbarkeit prüfen',
      buttonClass: 'bg-swopa-secondary-light-blue opacity-50 cursor-help',
      helpText: 'Füllen Sie die PZN aus und wählen Sie die Schaltfläche Apotheke to activate.',
      disabled: true,
    },
    idle: {
      buttonContent: 'Verfügbarkeit prüfen',
      buttonClass: 'bg-swopa-secondary-light-blue hover:bg-[#2B81EC] cursor-pointer',
      helpText: 'Klicken Sie hier, um die Verfügbarkeit von Medikamenten zu prüfen.',
      disabled: false,
    },
    loading: {
      buttonContent: <Spinner fill="#fff" height="20px" width="20px" />,
      buttonClass: 'bg-gray-700 cursor-wait',
      helpText: 'Abruf der Verfügbarkeit von Medikamenten. Bitte warten Sie.',
      disabled: true,
    },
    success: {
      buttonContent: <CheckMark height="20px" width="20px" />,
      buttonClass: 'bg-green-700 cursor-help',
      helpText: 'Das Medikament ist in der Apotheke erhältlich.',
      disabled: true,
    },
    notFound: {
      buttonContent: <Warning height="20px" width="20px" />,
      buttonClass: 'bg-orange-500 cursor-help',
      helpText: 'Das Medikament ist in der Apotheke erhältlich.',
      disabled: true,
    },
    apiNotSupportedError: {
      buttonContent: <Error height="20px" width="20px" />,
      buttonClass: 'bg-red-700 cursor-help',
      helpText:
        'Die ausgewählte Apotheke unterstützt keine API. Bitte kontaktieren Sie den Administrator.',
      disabled: true,
    },
    generalError: {
      buttonContent: <Error height="20px" width="20px" />,
      buttonClass: 'bg-red-700 cursor-pointer',
      helpText:
        'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Administrator.',
      disabled: false,
    },
  };

  const [checkingState, setCheckingState] = useState<State>(stateMap.disabled);

  useEffect(() => {
    setCheckingState(props.pzn && props.pharmacyId ? stateMap.idle : stateMap.disabled);
  }, [props.pzn, props.pharmacyId]);

  const checkAvailability = async () => {
    setCheckingState(stateMap.loading);

    try {
      const response = await get(
        `medical/pharmacies/${props.pharmacyId}/pharmaceutical/${props.pzn}/`,
      );

      switch (response.status) {
        case 204:
          setCheckingState(stateMap.notFound);
          break;
        case 400: {
          setCheckingState(stateMap.apiNotSupportedError);
          break;
        }
        default: {
          setCheckingState(stateMap.success);
        }
      }
    } catch (e) {
      setCheckingState(stateMap.generalError);
      console.error(e);
    }
  };

  return (
    <button
      title={checkingState.helpText}
      type="button"
      disabled={checkingState.disabled}
      className={classNames(
        'relative ease-in-out duration-300 text-white uppercase font-radikal rounded-[4px] w-full text-button-text mt-6 flex items-center justify-center',
        checkingState.buttonClass,
      )}
      onClick={checkAvailability}
    >
      {checkingState.buttonContent}
    </button>
  );
}
