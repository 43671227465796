import classNames from 'classnames';
import { ChangeEvent, ReactNode, useState, useEffect } from 'react';
import Spinner from '../Spinner';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import PublicTooltip from 'components/PublicTooltip';
import { ReactComponent as Info } from 'icons/info3.svg';

export type PlainInputProps = {
  error?: string;
  loading?: boolean;
  finished?: boolean;
  isPublic?: boolean;
  overwriteList?: string[];
  avoidOverwriteList?: string[];
  isFullWidth?: boolean;
  label?: ReactNode;
  size?: 'small' | 'large';
} & Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size'
>;

export default function PlainInput(props: PlainInputProps) {
  const {
    error,
    label,
    loading,
    finished,
    size,
    isPublic,
    overwriteList,
    avoidOverwriteList,
    isFullWidth,
    ...rest
  } = props;
  const { onChange } = rest;
  const [isOverwritten, setIsOverwritten] = useState<boolean>(false);

  const doChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setIsOverwritten(false);
    onChange && onChange(ev);
  };

  useEffect(() => {
    if (overwriteList) {
      setIsOverwritten(overwriteList?.indexOf(props.name || '') !== -1);
    } else {
      setIsOverwritten(false);
    }
  }, [overwriteList]);

  const className = classNames(
    `rounded-md text-sm p-2 bg-swopa-white focus:outline-none focus:border-swopa-secondary-light-blue hover:border-swopa-secondary-light-blue group-hover:border-swopa-secondary-light-blue`,
    {
      'text-swopa-primary-dark-blue': !error,
      'text-swopa-warning-red border-current': error,
      'p-1 text-xs': size === 'small',
      'w-full': isFullWidth || false,
    },
    {
      'border border-solid border-swopa-grey-3 bg-white': !isOverwritten,
    },
    {
      'border border-solid border-swopa-secondary-light-blue bg-sky-100': isOverwritten,
    },
  );

  return (
    <div className={classNames(props.className, 'inline-block')}>
      <div className="flex">
        {label && (
          <div className="block text-xs mb-2 grow flex">
            <label htmlFor={rest.name}>{label}</label>
            {avoidOverwriteList && avoidOverwriteList?.indexOf(rest.name as string) !== -1 && (
              <div className="ml-1">
                <Info />
              </div>
            )}
          </div>
        )}
        {isPublic && <PublicTooltip />}
      </div>
      <div className="flex group text-swopa-primary-dark-blue">
        <input {...rest} onChange={doChange} className={className} />
        {loading ? (
          <Spinner className="self-center fill-current ml-2" />
        ) : (
          <>{!finished && <CheckMark className="ml-2 self-center" />}</>
        )}
      </div>
      {error && <div className="text-swopa-warning-red">{error}</div>}
    </div>
  );
}
