import { useMemo } from 'react';
import type { Localization } from '../localizations/types';
import images from '../localizations/images';

type LocalizedImages = {
  logo: string;
  logo_white: string;
};

export default function useLocalizedImages(): LocalizedImages {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as Localization;

  return useMemo((): LocalizedImages => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return {
      logo: images.logo[LOCALIZATION],
      logo_white: images.logo_white[LOCALIZATION],
    };
  }, [LOCALIZATION]);
}
