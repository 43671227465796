import classNames from 'classnames';
import { createAnamnesis, useAnamneses } from 'data/useAnamnesis';
import { createExamination, useExaminations } from 'data/useExaminations';
import { createNote, useNotes } from 'data/useNotes';
import { createIndication, useIndications } from 'data/useIndication';
import { Patient } from 'data/usePatients';
import { createPrescription, usePrescriptions } from 'data/usePrescriptions';
import { ReactComponent as Plus } from 'icons/plus.svg';
import { ReactComponent as CheckMark } from 'icons/checkmark2.svg';
import { ButtonHTMLAttributes, PropsWithChildren, useContext, useState } from 'react';
import { GlobalErrorContext } from 'contexts/GlobalError';
import { useAppointmentContext } from 'contexts/Appointment';
import { ReceiptModal } from './forms/ReceiptModal';
import { Doctor } from '../../types/doctor';

export default function PatientActions({
  patient,
  appointmentId,
  doctor,
  pharmacyId,
}: {
  patient: Patient;
  appointmentId: number;
  doctor: Doctor;
  pharmacyId: number | null;
}) {
  const { setError } = useContext(GlobalErrorContext);
  const { mutate: mutateAnamneses } = useAnamneses(patient.id, appointmentId);
  const { mutate: mutateExaminations } = useExaminations(patient.id, appointmentId);
  const { mutate: mutateNotes } = useNotes(patient.id, appointmentId);
  const { mutate: mutatePrescriptions, results: prescriptions } = usePrescriptions(
    patient.id,
    appointmentId,
  );
  const { mutate: mutateIndications } = useIndications(patient.id, appointmentId);
  const { sectionExistsInDOM, setActive, setSelectedSection, setShowEmptyDiagnosis, state } =
    useAppointmentContext();
  const [showReceiptModal, setShowReceiptModal] = useState(false);

  return (
    <div className="border-solid border-[#f0f0f0] border bg-[#fafafa] p-3 rounded-md mb-10">
      <PlusButton
        onClick={async () => {
          if (sectionExistsInDOM('anamnesis')) return;
          try {
            await createAnamnesis({ id: patient.id, appointmentId });
            await mutateAnamneses();
            setSelectedSection('anamnesis');
            setActive('anamnesis');
          } catch (e: any) {
            setError(e);
            setSelectedSection('');
          }
        }}
        active={state.anamnesisActive}
        multiple={false}
      >
        Anamnese
      </PlusButton>
      <PlusButton
        onClick={async () => {
          if (sectionExistsInDOM('examination')) return;
          try {
            await createExamination({ id: patient.id, appointmentId });
            await mutateExaminations();
            setSelectedSection('examination');
            setActive('examination');
          } catch (e: any) {
            setError(e);
            setSelectedSection('');
          }
        }}
        active={state.examinationActive}
        multiple={false}
      >
        Körperliche Untersuchung
      </PlusButton>
      <PlusButton
        onClick={() => {
          if (sectionExistsInDOM('diagnosis')) return;
          setShowEmptyDiagnosis(true);
          setSelectedSection('diagnosis');
          setActive('diagnosis');
        }}
        active={state.diagnosisActive}
        multiple={false}
      >
        Diagnose
      </PlusButton>
      <PlusButton
        onClick={async () => {
          if (sectionExistsInDOM('indication')) return;
          try {
            await createIndication({ patient: patient.id, appointmentId });
            await mutateIndications();
            setSelectedSection('indication');
            setActive('indication');
          } catch (e: any) {
            setError(e);
            setSelectedSection('');
          }
        }}
        active={state.indicationActive}
        multiple={false}
      >
        Indikation
      </PlusButton>
      <PlusButton
        onClick={async () => {
          try {
            await createPrescription({
              patient: patient.id,
              appointmentId,
            });
            await mutatePrescriptions();
            setSelectedSection('prescription');
            setActive('prescription');
          } catch (e: any) {
            setError(e);
            setSelectedSection('');
          }
        }}
        active={false}
        multiple={prescriptions.length > 0}
      >
        Verschreibung
      </PlusButton>
      <PlusButton
        onClick={async () => {
          if (sectionExistsInDOM('note')) return;
          try {
            await createNote({ patient: patient.id, appointmentId });
            await mutateNotes();
            setSelectedSection('note');
            setActive('note');
          } catch (e: any) {
            setError(e);
            setSelectedSection('');
          }
        }}
        active={state.noteActive}
        multiple={false}
      >
        Notiz
      </PlusButton>
      <PlusButton active={false} multiple={false} onClick={() => setShowReceiptModal(true)}>
        PDF Rezept
      </PlusButton>
      <ReceiptModal
        open={showReceiptModal}
        prescriptions={prescriptions}
        patient={patient}
        doctor={doctor}
        pharmacyId={pharmacyId}
        onClose={() => setShowReceiptModal(false)}
      />
    </div>
  );
}

type PlusButtonProps = PropsWithChildren<{}> &
  ButtonHTMLAttributes<HTMLButtonElement> & { active: boolean; multiple?: boolean };

function PlusButton({ children, className, active, multiple, ...rest }: PlusButtonProps) {
  return (
    <button
      {...rest}
      className={classNames(
        className,
        'inline-flex group items-center m-1 last:mr-0 px-4 py-0.5 text-swopa-secondary-light-blue border border-solid border-swopa-secondary-light-blue rounded-md hover:text-white hover:bg-swopa-secondary-light-blue disabled:text-swopa-grey-4 disabled:border-current',
        { 'bg-[#E7F0FA]': active || multiple },
        { 'hover:bg-[#E7F0FA]': active || multiple },
        { 'hover:text-swopa-secondary-light-blue': active || multiple },
        { 'pointer-events-none': active },
      )}
    >
      {active || multiple ? (
        <>
          <CheckMark className="stroke-current mr-1 group-hover:hidden h-[9px]" />
          <Plus
            className={classNames('stroke-current mr-2 fill-current h-[9px]', {
              'hidden group-hover:inline-block': multiple || active,
            })}
          />
        </>
      ) : (
        <Plus className="stroke-current fill-current mr-1" />
      )}
      {children}
    </button>
  );
}
