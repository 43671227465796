import { useMemo } from 'react';
import { type Localization } from '../localizations/types';
import companyInformation from '../localizations/companyInformation';

type LocalizedCompanyInformation = {
  company_name: string;
  company_street: string;
  company_city: string;
  company_phone: string;
  company_email: string;
};

export default function useLocalizedCompanyInformation(): LocalizedCompanyInformation {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as Localization;

  return useMemo((): LocalizedCompanyInformation => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return {
      company_name: companyInformation.name[LOCALIZATION],
      company_street: companyInformation.street[LOCALIZATION],
      company_city: companyInformation.city[LOCALIZATION],
      company_phone: companyInformation.phone[LOCALIZATION],
      company_email: companyInformation.email[LOCALIZATION],
    };
  }, [LOCALIZATION]);
}
