import { ImagesLocalization } from './types';
import logo_ch from '../assets/logo_ch.png';
import logo_de from '../assets/logo_nav_de.png';
import logo_white_de from '../assets/logo_white_de.svg';

const images: ImagesLocalization = {
  logo: {
    DE: logo_de,
    CH: logo_ch,
  },
  logo_white: {
    DE: logo_white_de,
    CH: logo_ch,
  },
};

export default images;
