import useSWR from 'swr';

type DocumentData = {
  results: Array<Document>;
  count: number;
  next?: string | null;
  previous?: string | null;
};

export type Document = {
  id: number;
  patient: number;
  asset: string;
  uploaded_by: number;
  uploaded_at: string;
  name: string;
  content_type: string;
  uploaded_by_shortname: number;
  uploaded_by_fullname: number;
  uploaded_by_picture: number;
  appointment: number | null;
  external_id: string;
};

export function useDocuments(patientId: number) {
  const { data, error, mutate } = useSWR<DocumentData>(`medical/documents/?patient=${patientId}`);
  return { data: data?.results as any, error, mutate };
}
