import { useMemo } from 'react';
import { type Localization } from '../localizations/types';
import marketingPageLinks from '../localizations/marketingPageLinks';

type LocalizedMarketingPageLinks = {
  impressum: string;
  privacy_policy: string;
};

export default function useLocalizedMarketingPageLinks(): LocalizedMarketingPageLinks {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as Localization;
  const MARKETING_WEBSITE_BASE_URL = process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL;

  return useMemo((): LocalizedMarketingPageLinks => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    if (MARKETING_WEBSITE_BASE_URL === undefined) {
      throw new Error('Marketing website base url not set, please specify it.');
    }

    return {
      impressum: MARKETING_WEBSITE_BASE_URL + marketingPageLinks.impressum[LOCALIZATION],
      privacy_policy: MARKETING_WEBSITE_BASE_URL + marketingPageLinks.privacy_policy[LOCALIZATION],
    };
  }, [LOCALIZATION, MARKETING_WEBSITE_BASE_URL]);
}
