import { useMemo } from 'react';
import { type Localization } from '../localizations/types';

type LocalizedDocumentCategory = {
  label: string;
  value: string;
};

const fileCategories = [
  { label: 'Kategorie auswählen', value: '' },
  { label: 'Arztbrief stationär', value: 'doctor_letter_inpatient' },
  { label: 'Arztbrief ambulant', value: 'doctor_letter_outpatient' },
  { label: 'Gutachten', value: 'assessment' },
  { label: 'Rechnung', value: 'bill' },
  { label: 'Rezept', value: 'recipe' },
  { label: 'Unterschriebenes Rezept', value: 'signed_prescription', localization: 'DE' },
  { label: 'Überweisung', value: 'transfer' },
  { label: 'Datenschutzerklärung', value: 'privacy_policy' },
  { label: 'Datenverarbeitungsvereinbarung', value: 'data_processing_agreement' },
  { label: 'AGB', value: 'agb' },
  { label: 'Aufklärung', value: 'enlightenment' },
  { label: 'Information über privatärztliche Behandlung', value: 'private_medical_treatment' },
  { label: 'Personalausweis', value: 'id' },
  { label: 'Reisepass', value: 'passport' },
  { label: 'Krankenkassenkarte', value: 'health_insurance_card' },
  { label: 'Sonstiges', value: 'miscellaneous' },
];

export default function useLocalizedDocumentCategories(): Array<LocalizedDocumentCategory> {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as Localization;

  return useMemo((): Array<LocalizedDocumentCategory> => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return fileCategories
      .filter((fc) => fc.localization === undefined || fc.localization === LOCALIZATION)
      .map((fc) => ({ label: fc.label, value: fc.value }));
  }, [LOCALIZATION]);
}
